import Chart from "../../components/Chart/Chart.vue";
export default {
    name: "DailyHighlights",
    created () {
        this.$nextTick(function () {
            this.GetDeployedWarrantOfTheDay()
        });
    },
    mounted () {
        this.$nextTick(function () {

        })
    },
    data () {
        return {
            DailyHighlightsData: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 Daily Highlights ric 数据
        GetDeployedWarrantOfTheDay: function () {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedWarrantOfTheDay",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.text[0], function (i, c) {
                            if (i.indexOf(i.replace(/ric/gi, "")) > 0) {
                                ricArr[Number(i.replace(/[^0-9]/gi, "")) - 1] = c;
                            }
                        })
                        this.DailyHighlightsGetMarketData(ricArr)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 Daily Highlights 详细数据
        DailyHighlightsGetMarketData: function (ric) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        console.log(data);
                        $.each(ric, (i, c) => {
                            this.DailyHighlightsData.push({
                                overallData: data[c],
                                nameData: [{
                                    name: 'Warrant Type',
                                    data: data[c].type
                                },
                                {
                                    name: 'Bid',
                                    data: `${this.$$.curreryRetrun(data[c].currency)}  ${data[c].BID}`,
                                    data2: `${this.$$.changeRate(this.$$.formatNumber(data[c].PCTCHNG, 1, 0))} %`
                                },
                                {
                                    name: 'Underlying Share',
                                    data: data[c].underlying_ticker
                                },
                                {
                                    name: 'Underlying Bid',
                                    data: `${this.$$.curreryRetrun(data[c].underlying_curr)} ${this.$$.reservedNumber(data[c].underlying_bid)}`,
                                    data2: `${this.$$.changeRate(this.$$.formatNumber(data[c].underlying_price, 1, 0))} %`
                                },
                                {
                                    name: 'Exercise Price',
                                    data: data[c].exercise_price !== "N/A" ? this.$$.curreryRetrun(data[c].underlying_curr) + " " + this.$$.formatNumber(data[c].exercise_price, 3, 0) : "N/A"
                                },
                                {
                                    name: 'Exercise Ratio',
                                    data: data[c].conv_ratio_cal !== "N/A" ? data[c].conv_ratio_cal + ` : 1` : "N/A"
                                },
                                {
                                    name: 'Moneyness',
                                    data: data[c].percent_moneyness !== "N/A" ? this.$$.formatNumber(data[c].percent_moneyness, 1, 0) + " % " + this.getMoneyness(data[c].moneyness) : 'N/A'
                                },
                                {
                                    name: 'Premium',
                                    data: data[c].premium !== 'N/A' ? this.$$.formatNumber(data[c].premium, 1, 0) + " %" : "N/A",
                                    top: 80,
                                    left: 0,
                                },
                                {
                                    name: 'Effective Gearing',
                                    data: data[c].effective_gearing !== 'N/A' ? this.$$.formatNumber(data[c].effective_gearing, 1, 0) + " x" : "N/A",
                                    top: 70,
                                    left: 0,
                                },
                                {
                                    name: 'Expiry',
                                    data: data[c].maturity
                                }
                                ]
                            })
                        })
                    }

                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        getMoneyness (value) {
            if (value == "0") {
                return "ATM";
            } else if (value == "1") {
                return "ITM";
            } else if (value == "2") {
                return "OTM";
            } else {
                return value;
            }
        },
    },
    components: {
        Chart
    }
};